import "firebase/analytics"

import Provider from "./src/context/provider"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it

export const wrapRootElement = Provider
