// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-administration-documentation-js": () => import("./../src/pages/administration/documentation.js" /* webpackChunkName: "component---src-pages-administration-documentation-js" */),
  "component---src-pages-administration-index-js": () => import("./../src/pages/administration/index.js" /* webpackChunkName: "component---src-pages-administration-index-js" */),
  "component---src-pages-administration-rate-js": () => import("./../src/pages/administration/rate.js" /* webpackChunkName: "component---src-pages-administration-rate-js" */),
  "component---src-pages-bedside-checklist-js": () => import("./../src/pages/bedside-checklist.js" /* webpackChunkName: "component---src-pages-bedside-checklist-js" */),
  "component---src-pages-compatibility-caveats-js": () => import("./../src/pages/compatibility/caveats.js" /* webpackChunkName: "component---src-pages-compatibility-caveats-js" */),
  "component---src-pages-compatibility-component-choice-js": () => import("./../src/pages/compatibility/component-choice.js" /* webpackChunkName: "component---src-pages-compatibility-component-choice-js" */),
  "component---src-pages-compatibility-index-js": () => import("./../src/pages/compatibility/index.js" /* webpackChunkName: "component---src-pages-compatibility-index-js" */),
  "component---src-pages-compatibility-results-js": () => import("./../src/pages/compatibility/results.js" /* webpackChunkName: "component---src-pages-compatibility-results-js" */),
  "component---src-pages-disclaimer-js": () => import("./../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-consent-considerations-js": () => import("./../src/pages/patient-consent-considerations.js" /* webpackChunkName: "component---src-pages-patient-consent-considerations-js" */),
  "component---src-pages-patient-consent-js": () => import("./../src/pages/patient-consent.js" /* webpackChunkName: "component---src-pages-patient-consent-js" */),
  "component---src-pages-positive-patient-identification-js": () => import("./../src/pages/positive-patient-identification.js" /* webpackChunkName: "component---src-pages-positive-patient-identification-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-requirements-cmv-negative-components-elective-js": () => import("./../src/pages/requirements/cmv-negative-components/elective.js" /* webpackChunkName: "component---src-pages-requirements-cmv-negative-components-elective-js" */),
  "component---src-pages-requirements-cmv-negative-components-granulocyte-transfusion-js": () => import("./../src/pages/requirements/cmv-negative-components/granulocyte-transfusion.js" /* webpackChunkName: "component---src-pages-requirements-cmv-negative-components-granulocyte-transfusion-js" */),
  "component---src-pages-requirements-cmv-negative-components-index-js": () => import("./../src/pages/requirements/cmv-negative-components/index.js" /* webpackChunkName: "component---src-pages-requirements-cmv-negative-components-index-js" */),
  "component---src-pages-requirements-cmv-negative-components-iut-neonates-js": () => import("./../src/pages/requirements/cmv-negative-components/iut-neonates.js" /* webpackChunkName: "component---src-pages-requirements-cmv-negative-components-iut-neonates-js" */),
  "component---src-pages-requirements-index-js": () => import("./../src/pages/requirements/index.js" /* webpackChunkName: "component---src-pages-requirements-index-js" */),
  "component---src-pages-requirements-irradiation-car-t-therapy-js": () => import("./../src/pages/requirements/irradiation/car-t-therapy.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-car-t-therapy-js" */),
  "component---src-pages-requirements-irradiation-exchange-transfusion-js": () => import("./../src/pages/requirements/irradiation/exchange-transfusion.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-exchange-transfusion-js" */),
  "component---src-pages-requirements-irradiation-granulocyte-administration-js": () => import("./../src/pages/requirements/irradiation/granulocyte-administration.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-granulocyte-administration-js" */),
  "component---src-pages-requirements-irradiation-hla-matched-components-js": () => import("./../src/pages/requirements/irradiation/hla-matched-components.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-hla-matched-components-js" */),
  "component---src-pages-requirements-irradiation-hodgkin-lymphoma-js": () => import("./../src/pages/requirements/irradiation/hodgkin-lymphoma.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-hodgkin-lymphoma-js" */),
  "component---src-pages-requirements-irradiation-hsc-donors-js": () => import("./../src/pages/requirements/irradiation/hsc-donors.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-hsc-donors-js" */),
  "component---src-pages-requirements-irradiation-hsc-recipients-js": () => import("./../src/pages/requirements/irradiation/hsc-recipients.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-hsc-recipients-js" */),
  "component---src-pages-requirements-irradiation-hsc-transplant-js": () => import("./../src/pages/requirements/irradiation/hsc-transplant.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-hsc-transplant-js" */),
  "component---src-pages-requirements-irradiation-immunosuppressant-therapies-js": () => import("./../src/pages/requirements/irradiation/immunosuppressant-therapies.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-immunosuppressant-therapies-js" */),
  "component---src-pages-requirements-irradiation-index-js": () => import("./../src/pages/requirements/irradiation/index.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-index-js" */),
  "component---src-pages-requirements-irradiation-intrauterine-transfusion-js": () => import("./../src/pages/requirements/irradiation/intrauterine-transfusion.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-intrauterine-transfusion-js" */),
  "component---src-pages-requirements-irradiation-lymphocyte-immunodeficiency-js": () => import("./../src/pages/requirements/irradiation/lymphocyte-immunodeficiency.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-lymphocyte-immunodeficiency-js" */),
  "component---src-pages-requirements-irradiation-transfusion-from-relative-js": () => import("./../src/pages/requirements/irradiation/transfusion-from-relative.js" /* webpackChunkName: "component---src-pages-requirements-irradiation-transfusion-from-relative-js" */),
  "component---src-pages-requirements-monoclonal-therapies-index-js": () => import("./../src/pages/requirements/monoclonal-therapies/index.js" /* webpackChunkName: "component---src-pages-requirements-monoclonal-therapies-index-js" */),
  "component---src-pages-requirements-washed-red-cells-index-js": () => import("./../src/pages/requirements/washed-red-cells/index.js" /* webpackChunkName: "component---src-pages-requirements-washed-red-cells-index-js" */),
  "component---src-pages-taco-assessment-js": () => import("./../src/pages/taco/assessment.js" /* webpackChunkName: "component---src-pages-taco-assessment-js" */),
  "component---src-pages-taco-index-js": () => import("./../src/pages/taco/index.js" /* webpackChunkName: "component---src-pages-taco-index-js" */),
  "component---src-pages-taco-manage-risk-js": () => import("./../src/pages/taco/manage-risk.js" /* webpackChunkName: "component---src-pages-taco-manage-risk-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-transfusion-reaction-immediate-actions-js": () => import("./../src/pages/transfusion-reaction/immediate-actions.js" /* webpackChunkName: "component---src-pages-transfusion-reaction-immediate-actions-js" */),
  "component---src-pages-transfusion-reaction-index-js": () => import("./../src/pages/transfusion-reaction/index.js" /* webpackChunkName: "component---src-pages-transfusion-reaction-index-js" */),
  "component---src-pages-transfusion-reaction-next-steps-js": () => import("./../src/pages/transfusion-reaction/next-steps.js" /* webpackChunkName: "component---src-pages-transfusion-reaction-next-steps-js" */),
  "component---src-pages-transfusion-reaction-other-symptoms-js": () => import("./../src/pages/transfusion-reaction/other-symptoms.js" /* webpackChunkName: "component---src-pages-transfusion-reaction-other-symptoms-js" */)
}

