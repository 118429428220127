import React, { useState } from "react"

export const TermsContext = React.createContext()

const Provider = props => {
  const [termsAccepted, setTermsAccepted] = useState(false)

  return (
    <TermsContext.Provider
      value={{
        termsAccepted,
        acceptTerms: () => setTermsAccepted(true),
        rejectTerms: () => setTermsAccepted(false),
      }}
    >
      {props.children}
    </TermsContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
